import React from 'react';
import { Carousel } from 'antd';

class EquipmentsCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carousel = React.createRef();
  }

  render() {
    const { items, settings } = this.props;
    return (
      <div className="equipments-carousel">

        <div className="equipments-carousel__arrows equipments-carousel__arrows--left">
          <div className="equipments-carousel__arrows--left">
            <button
              className="equipments-carousel__arrows__button"
              onClick={() => this.carousel.prev()}
              type="button"
            >
              <img
                src="/assets/img/ic_chevron_left_white.png"
                alt="carousel prev"
              />
            </button>
          </div>
        </div>

        <div className="equipments-carousel__arrows equipments-carousel__arrows--right">
          <button
            className="equipments-carousel__arrows__button"
            onClick={() => this.carousel.next()}
            type="button"
          >
            <img
              src="/assets/img/ic_chevron_right_white.png"
              alt="carousel next"
            />
          </button>
        </div>

        <Carousel ref={(node) => (this.carousel = node)} {...settings}>
          {items.map((item, key) => (
            <div key={key.toString()}>
              <div className="equipments-carousel__item">
                <div className="row">
                  <div className="col-lg-5">
                    <div
                      className="equipments-carousel__item__image"
                      style={{ backgroundImage: `url(${item.imageUrl})` }}
                    />
                  </div>
                  <div className="col-lg-7">
                    <div
                      className="equipments-carousel__item__desc"
                    >
                      <p>
                        <span className="equipments-carousel__item__desc__header">
                          {item.header}
                        </span>
                        <span className="equipments-carousel__item__desc__text">
                          {item.text}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}

export default EquipmentsCarousel;
