import React from 'react';
import { Router } from '@reach/router';

import Public from './public';
import Home from '../containers/home';
import AppConfig from '../config/app';

class Routes extends React.PureComponent {
  render() {
    return (
      <Router>
        <Public
          path="/"
          title={`${AppConfig.name} - Bem vindo(a)!`}
          container={<Home />}
        />
      </Router>
    );
  }
}

export default Routes;
