import React from 'react';

class WhatsAppShareButton extends React.PureComponent {
  render() {
    const { customStyle } = this.props;

    return (
      <div
        className="whatsapp-share-button"
        style={customStyle || undefined}
      >
        <a
          className="whatsapp-share-button__inner"
          type="link"
          href="https://api.whatsapp.com/send?1=pt_br&phone=+5519996692538"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="whatsapp-share-button__inner__img"
            src="/assets/img/ic_whatsapp.png"
            alt="whatsapp support"
          />
        </a>
      </div>
    );
  }
}

export default WhatsAppShareButton;
