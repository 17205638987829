import React from 'react';
import iconFooterOne from '../../svg/footer-new/ic_footer_new_1.svg';
import iconFooterTwo from '../../svg/footer-new/ic_footer_new_2.svg';
import iconFooterThree from '../../svg/footer-new/ic_footer_new_3.svg';
import iconFooterFour from '../../svg/footer-new/ic_footer_new_4.svg';

class Footer extends React.PureComponent {
  render() {
    return (
      <div
        className="footer"
        id="contato"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="footer__title">
                Contato
              </h3>
            </div>
          </div>

          <div className="footer__contact">
            <div className="row">

              <div className="col-lg-6">
                <div className="footer__contact__data">
                  <div className="footer__contact__data__item">
                    <span className="footer__contact__data__item__icon">
                      <img
                        src={iconFooterOne}
                        alt="item icon"
                      />
                    </span>
                    <span className="footer__contact__data__item__text">
                      <strong className="footer__contact__data__item__text__title">
                        Horário de funcionamento
                      </strong>
                      <br />
                      Segunda a sexta, das 7h30 às 17h30
                    </span>
                  </div>

                  <div className="footer__contact__data__item">
                    <span className="footer__contact__data__item__icon">
                      <img
                        src={iconFooterTwo}
                        alt="item icon"
                      />
                    </span>
                    <span className="footer__contact__data__item__text">
                      Rua da Blenda, 270
                      <br />
                      Santa Bárbara D’Oeste | SP
                      <br />
                      CEP: 13454-189
                    </span>
                  </div>

                  <div className="footer__contact__data__item">
                    <span className="footer__contact__data__item__icon">
                      <img
                        src={iconFooterThree}
                        alt="item icon"
                      />
                    </span>
                    <span className="footer__contact__data__item__text">
                      19 99669.2538
                      <br />
                      19 99693.1584
                    </span>
                  </div>

                  <div className="footer__contact__data__item">
                    <span className="footer__contact__data__item__icon">
                      <img
                        src={iconFooterFour}
                        alt="item icon"
                      />
                    </span>
                    <a
                      className="footer__contact__data__item__text footer__contact__data__item__text--link"
                      href="mailto:contato@jmmanutencao.com.br"
                    >
                      contato@jmmanutencao.com.br
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-2">
                <p className="footer__contact__phrase">
                  Solicite um orçamento e confira
                  nossas soluções personalizadas
                  para a sua empresa.
                </p>

                <a
                  className="footer__contact__button"
                  href="https://forms.gle/tmtS1BTK7Hm4L2RVA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SOLICITE UM ORÇAMENTO
                </a>
              </div>
            </div>
          </div>

          <div className="footer__copyright">
            <div className="row">
              <div className="col-12">
                <span className="footer__copyright__item">&copy; JM Soluções Industriais EIRELI</span>
                <span className="footer__copyright__item">CNPJ: 17.659.174/0001-63</span>
                {/*<span className="footer__copyright__item">
                  <Button
                    type="link"
                    href="https://www.google.com/search?sxsrf=ALeKk03DAojPYGHGJPlnaZWxGWMWsHZBrA%3A1599682844090&ei=HDlZX4yFBbTE5OUP05mxgAU&q=termos+legais&oq=termos+legais&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB46BAgAEEc6BAgjECc6BAgAEEM6BQgAELEDOgIILlC3RFjqTWDyTmgAcAN4AIAB1QKIAcoJkgEHMS43LjAuMZgBAKABAaoBB2d3cy13aXrAAQE&sclient=psy-ab&ved=0ahUKEwiMg7P98tzrAhU0IrkGHdNMDFAQ4dUDCA0&uact=5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos legais
                  </Button>
                </span>
                <span className="footer__copyright__item">
                  <Button
                    type="link"
                    href="https://www.google.com/search?sxsrf=ALeKk01bDFl9jYbWvm6RF7L6Q4Q6d6VAQw%3A1599682855132&ei=JzlZX7W8B5_B5OUPhdCYmAY&q=Pol%C3%ADtica+de+privacidade&oq=Pol%C3%ADtica+de+privacidade&gs_lcp=CgZwc3ktYWIQDDIFCAAQsQMyBAgAEEMyAggAMgIIADICCAAyAggAMgIIADICCAAyAggAMgIIADoECAAQR1Cs_QFYrP0BYNKCAmgAcAJ4AIABiAGIAYgBkgEDMC4xmAEAoAECoAEBqgEHZ3dzLXdpesABAQ&sclient=psy-ab&ved=0ahUKEwj169SC89zrAhWfILkGHQUoBmMQ4dUDCA0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de privacidade
                  </Button>
                </span>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
