import React from 'react';
import { Carousel } from 'antd';

class ClientsCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carousel = React.createRef();
  }

  render() {
    const { items, settings } = this.props;
    return (
      <div className="clients-carousel">
        <div className="clients-carousel__arrows clients-carousel__arrows--left">
          <div className="clients-carousel__arrows--left">
            <button
              className="clients-carousel__arrows__button"
              onClick={() => this.carousel.prev()}
              type="button"
            >
              <img
                src="/assets/img/ic_chevron_left_blue.png"
                alt="carousel prev"
              />
            </button>
          </div>
        </div>

        <div className="clients-carousel__arrows clients-carousel__arrows--right">
          <button
            className="clients-carousel__arrows__button"
            onClick={() => this.carousel.next()}
            type="button"
          >
            <img
              src="/assets/img/ic_chevron_right_blue.png"
              alt="carousel next"
            />
          </button>
        </div>

        <Carousel ref={(node) => (this.carousel = node)} {...settings}>
          {items.map((item, key) => (
            <div key={key.toString()}>
              <div className="clients-carousel__item">
                <p>
                  <span className="clients-carousel__item__icon">
                    <img
                      src={item.icon}
                      alt="service icon"
                    />
                  </span>
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}

export default ClientsCarousel;
