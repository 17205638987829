import React from 'react';
import ClientsCarousel from '../clients-carousel';
import ContactIconOne from '../../svg/ic_contact_1.svg';
import ContactIconTwo from '../../svg/ic_contact_2.svg';
import ContactIconThree from '../../svg/ic_contact_3.svg';
import ContactIconFour from '../../svg/ic_contact_4.svg';
import ContactIconFive from '../../svg/ic_contact_5.svg';
import ContactIconSix from '../../svg/ic_contact_6.svg';

const items = [
  {
    id: 1,
    icon: ContactIconOne,
  },
  {
    id: 2,
    icon: ContactIconTwo,
  },
  {
    id: 3,
    icon: ContactIconThree,
  }, {
    id: 4,
    icon: ContactIconFour,
  },
  {
    id: 5,
    icon: ContactIconFive,
  },
  {
    id: 6,
    icon: ContactIconSix,
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Clients extends React.PureComponent {
  render() {
    return (
      <div
        className="clients"
        id="clientes"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="clients__title">
                Clientes atendidos
              </h3>
            </div>
          </div>
          <div className="clients__logo">
            <div className="row justify-content-center row-eq-height">
              <div className="col-lg-12">
                <ClientsCarousel
                  items={items}
                  settings={settings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
