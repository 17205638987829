import React from 'react';
import { Helmet } from 'react-helmet';

class Public extends React.PureComponent {
  render() {
    const { title, container } = this.props;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {React.cloneElement(container)}
      </div>
    );
  }
}

export default Public;
