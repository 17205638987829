import React from 'react';
import AdvancedCarousel from '../advanced-carousel';

import ServiceIconOne from '../../svg/ic_service_1.svg';
import ServiceIconTwo from '../../svg/ic_service_2.svg';
import ServiceIconThree from '../../svg/ic_service_3.svg';
import ServiceIconFour from '../../svg/ic_service_5.svg';
import ServiceIconFive from '../../svg/ic_service_4.svg';
import ServiceIconSix from '../../svg/ic_service_6.svg';
import ServiceIconSeven from '../../svg/ic_service_7.svg';
import ServiceIconEight from '../../svg/ic_service_8.svg';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const carouselItems = [
  {
    id: 1,
    header: 'Elétrica: ',
    text: 'em equipamentos e processos industriais, identificando falhas e permitindo suas correções.',
    icon: ServiceIconOne,
  },
  {
    id: 2,
    header: 'Eletrônica: ',
    // eslint-disable-next-line max-len
    text: 'para equipamentos industriais, manutenção e parametrização de drives, automação industrial e outros recursos eletrônicos.',
    icon: ServiceIconTwo,
  },
  {
    id: 3,
    header: 'Hidráulica: ',
    text: 'nos circuitos e dispositivos hidráulicos, bombeamento e válvulas.',
    icon: ServiceIconThree,
  },
  {
    id: 4,
    header: 'Mecânica: ',
    // eslint-disable-next-line max-len
    text: 'conjunto de rolamentos, engrenagens, polias, correias, folgas de transmissão e alinhamento de conjuntos mecânicos.',
    icon: ServiceIconFour,
  },
  {
    id: 5,
    header: 'Automação: ',
    text: 'integração de máquina robô em vários tipos de processos.',
    icon: ServiceIconFive,
  },
  {
    id: 6,
    header: 'Pneumática: ',
    text: 'montagem e instalação de painéis pneumáticos com ou sem NR12.',
    icon: ServiceIconSix,
  },
  {
    id: 7,
    header: 'Reforma: ',
    text: 'reforma de máquinas e equipamentos de todos os segmentos da indústria.',
    icon: ServiceIconSeven,
  },
  {
    id: 8,
    header: 'Corte por jato de água: ',
    text: 'cortes por jato de água em chapas de alumínio, aço carbono e inox.',
    icon: ServiceIconEight,
  },
];

class Services extends React.PureComponent {
  render() {
    return (
      <div
        className="services"
        id="servicos"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="services__title">
                Serviços de manutenção
              </h3>
              <p className="services__prhase">
                A <span className="services__prhase__strong">JM Soluções Industriais</span> oferece mão-de-obra
                altamente capacitada para atender a diversos
                tipos de demanda de manutenção, com agilidade, eficiência e excelência:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <AdvancedCarousel
                settings={settings}
                items={carouselItems}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
