import React from 'react';
import { Button, Carousel } from 'antd';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Navigation from '../navigation';
import AppConfig from '../../config/app';

class Header extends React.PureComponent {
  render() {
    return (
      <div
        className="header"
        id="inicio"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">

              <Navigation />

              <Carousel className="header__carousel">
                <div>
                  <div className="header__carousel__item">
                    <div className="row">
                      <div className="col-lg-4">
                        <p className="header__carousel__item__phrase">
                          Soluções ágeis, seguras e
                          eficientes em manutenção
                          industrial para sua
                          empresa <span className="header__carousel__item__phrase__strong">não parar</span>.
                        </p>
                        <div className="header__carousel__item__detail">
                          <img
                            className="header__carousel__item__detail__img"
                            src="/assets/img/ic_header_detail.png"
                            alt="jm detail"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 offset-lg-4">
                        <p className="header__carousel__item__contact">
                          Clique abaixo e faça agora mesmo um orçamento de manutenção sem compromisso.
                          <br />
                          <br />
                          Qualidade garantida com mais de 10 anos de mercado.
                        </p>

                        <a
                          className="nav-link navigation__collapse__nav__item__link"
                          href="https://forms.gle/tmtS1BTK7Hm4L2RVA"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            className="header__carousel__item__contact__button"
                            type="primary"
                          >
                            SOLICITE UM ORÇAMENTO
                          </Button>
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
