import React from 'react';
import EquipmentsCarousel from '../equipments-carousel';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const carouselItems = [
  {
    id: 1,
    header: 'Torno mecânico Romi',
    text: 'Passagem no barramento: 700mm.\nNa cava: 1010 mm.\nComprimento entre pontas: 5000mm.',
    imageUrl: '/assets/img/products/img_product_one.jpeg',
  },
  {
    id: 2,
    header: 'Fresadora Ferramenteira',
    text: '',
    imageUrl: '/assets/img/products/img_product_two.jpeg',
  },
  {
    id: 3,
    header: 'Torno CNC',
    text: '',
    imageUrl: '/assets/img/products/img_product_three.jpeg',
  },
  {
    id: 4,
    header: 'Centro de Usinagem',
    text: '',
    imageUrl: '/assets/img/products/img_product_four.jpeg',
  },
  {
    id: 5,
    header: 'Automação',
    text: 'Retrofiting de máquinas em geral e construção de máquinas novas de acordo com a necessidade do cliente.',
    imageUrl: '/assets/img/products/img_product_five.jpeg',
  },
];

class Equipments extends React.PureComponent {
  render() {
    return (
      <div
        className="equipments"
        id="equipamentos"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="equipments__title">
                Equipamentos
              </h3>
              <p className="equipments__prhase">
                A <span className="equipments__prhase__strong">JM Soluções Industriais</span> oferece uma variedade de
                soluções para o setor de equipamentos e peças, possuindo em seu parque industrial maquinário de alta
                performance.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <EquipmentsCarousel
                settings={settings}
                items={carouselItems}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Equipments;
