import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import AppConfig from '../../config/app';
import LogoWhite from '../../svg/ic_logo_white.svg';

class Navigation extends React.PureComponent {
  render() {
    return (
      <div className="navigation__wrapper">
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <AnchorLink
            className="navbar-brand navigation__logo"
            href="#inicio"
          >
            <img
              src={LogoWhite}
              alt="JM Soluções Industriais logo"
            />
          </AnchorLink>
          <button
            className="navbar-toggler navigation__toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className="collapse navbar-collapse navigation__collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto navigation__collapse__nav">
              <li className="nav-item active navigation__collapse__nav__item">
                <AnchorLink
                  className="nav-link navigation__collapse__nav__item__link"
                  href="#inicio"
                >
                  empresa
                </AnchorLink>
              </li>
              <li className="nav-item active navigation__collapse__nav__item">
                <AnchorLink
                  className="nav-link navigation__collapse__nav__item__link"
                  href="#servicos"
                  offset={() => AppConfig.navbar.offset}
                >
                  serviços
                </AnchorLink>
              </li>
              <li className="nav-item active navigation__collapse__nav__item">
                <AnchorLink
                  className="nav-link navigation__collapse__nav__item__link"
                  href="#equipamentos"
                  offset={() => AppConfig.navbar.offset}
                >
                  equipamentos
                </AnchorLink>
              </li>
              <li className="nav-item active navigation__collapse__nav__item">
                <AnchorLink
                  className="nav-link navigation__collapse__nav__item__link"
                  href="#contato"
                  offset={() => AppConfig.navbar.offset}
                >
                  contato
                </AnchorLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navigation;
