import React from 'react';
import { Button } from 'antd';

class CookiesMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedTerms: localStorage.acceptedTerms,
    };
  }

  toggleAcceptedTerms() {
    localStorage.acceptedTerms = true;
    this.setState({ acceptedTerms: true });
  }

  render() {
    const { acceptedTerms } = this.state;

    return (
      !acceptedTerms && (
        <div className="cookies-message">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <strong className="cookies-message__title">
                  JM Soluções Industriais e o uso de cookies
                </strong>
                <p className="cookies-message__message">
                  Esse site usa cookies. Ao continuar a navegação, você concorda com a nossa&nbsp;
                  <a
                    className="cookies-message__message__link"
                    href="https://google.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    política de uso
                  </a>
                  &nbsp;de cookies.
                </p>
              </div>
              <div className="col-lg-3">
                <div className="cookies-message__button__wrapper">
                  <Button
                    className="cookies-message__button"
                    onClick={() => this.toggleAcceptedTerms()}
                  >
                    Eu aceito
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default CookiesMessage;
