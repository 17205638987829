import React from 'react';
import Header from '../components/header/header';
import Services from '../components/services';
import Equipments from '../components/equipments';
import Clients from '../components/clients';
import Footer from '../components/footer';
import WhatsAppShareButton from '../components/whatsAppShareButton/whatsAppShareButton';
import CookiesMessage from '../components/cookiesMessage/cookiesMessage';

class Home extends React.PureComponent {
  render() {
    return (
      <div>
        <Header />
        <Services />
        <Equipments />
        <Clients />
        <Footer />
        <WhatsAppShareButton />
        <CookiesMessage />
      </div>
    );
  }
}

export default Home;
